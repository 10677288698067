import { useState } from 'react'
import CustomButton from '../../../../ui/CustomButton'
import { HeadingTitle, Paper } from '../../../../ui/index'
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined'
import TableTraspaso from './TableTraspaso'
import useBlockedMonths from '../useBlockedMonths.hook'
import Modal from '@mui/material/Modal' 

const TraspasoDeApertura = () => {
  const { allMonthsBlocked } = useBlockedMonths()  // Hook para ver si todos los meses están bloqueados
  const [openModal, setOpenModal] = useState(false)  // Controlar estado del modal

  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  const handleNavigateToPeriods = () => {
    handleCloseModal()
  }

  return (
    <>
      {allMonthsBlocked ? (
        <TableTraspaso />
      ) : (
        <Paper>
          <HeadingTitle title='Traspaso de Apertura' />
          <div className='text-center mt-6'>
            <p className='text-lg'>Aún no se ha registrado un traspaso de apertura</p>
            <p className='text-sm mt-2'>
              Inicia el año contable con todos los datos correctos, aquí podrás registrar cómo finalizó el año contable.
            </p>
            <div className='flex justify-center mt-6 space-x-4'>
              <button
                className='bg-accent2 text-white font-semibold py-2 px-4 rounded-full'
                onClick={handleOpenModal}
              >
                GENERAR MANUAL
              </button>
              <CustomButton
                className='rounded-full text-white font-semibold'
                color='primary'
                title='GENERAR LUCA'
              />
            </div>
          </div>
        </Paper>
      )}

      {/* Modal */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <div className='bg-white p-6 rounded-lg mx-auto mt-20 max-w-md text-center shadow-lg'>
          <ErrorIcon className='text-red-500' style={{ fontSize: 40 }} />
          <h2 className='text-lg font-bold mt-4'>Hubo un error</h2>
          <p className='text-sm mt-2'>
            Todos los periodos del año anterior deben estar bloqueados para poder continuar.
          </p>
          <button
            className='bg-gray-500 text-white font-semibold py-2 px-4 rounded-full mt-4'
            onClick={handleNavigateToPeriods}  // Navegar a "Bloqueo de Períodos"
          >
            IR A BLOQUE DE PERIODOS
          </button>
        </div>
      </Modal>
    </>
  )
}

export default TraspasoDeApertura