import List from "./Comprobante/List"

const Comprobantes = () => {
  return (
    <div>
      <List />
    </div>
  )
}

export default Comprobantes
