import Layout from '../components/layouts'
import Comprobantes from '../components/modules/views/dashboard/MiContador/Comprobantes'
import Centralizacion from '../components/modules/views/dashboard/MiContador/Centralizacion'
import BloqueDePeriodos from '../components/modules/views/dashboard/MiContador/BloqueDePeriodos'
import Tabs from '../components/modules/tabs/Tabs'
import TraspasoDeApertura from '../components/modules/views/dashboard/MiContador/TraspadoApertura/TraspasoDeApertura'

const MiContador = () => {
  const data = [
    {
      title: 'Comprobantes',
      content: <Comprobantes />,
    },
    {
      title: 'Centralización',
      content: <Centralizacion />,
    },
    {
      title: 'Bloqueo de Períodos',
      content: <BloqueDePeriodos />,
    },
    {
      title: 'Traspaso de Apertura',
      content: <TraspasoDeApertura />,
    },
  ]

  return (
    <Layout>
      <div>
        <Tabs tabs={data}/>
      </div>
    </Layout>
  )
}

export default MiContador
