import { useContentTypeReportes } from './useReportes.hook'

//components
import NavBar from './NavBar'
import Layout from '../../components/layouts/index'
import ListLibroMayor from './libroMayor/ListLibroMayor'
import ListLibroDiario from './libroDiario/ListLibroDiario'
import ListBalanceGeneral from './balanceGeneral/ListBalanceGeneral'
import ListLibroAuxiliarProveedores from './libroAuxiliarProveedores/ListLibroAuxiliarProveedores'
import ListLibroAuxiliarCliente from './libroAuxiliarCliente/ListLibroAuxiliarCliente'
import ListEstadoDeResultados from './estadoDeResultado/ListEstadoDeResultados'

const Informes = () => {
  const typeContent = useContentTypeReportes(state => state.typeContent)

  const renderContent = () => {
    switch (typeContent) {
      case '1':
        return <div></div>
      case '2':
        return <ListEstadoDeResultados />
      case '3':
        return <div></div>
      case '4':
        return <div></div>
      case '5':
        return <ListBalanceGeneral />
      case '6':
        return <ListLibroDiario />
      case '7':
        return <ListLibroMayor />
      case '8':
        return <ListLibroAuxiliarCliente />
      case '9':
        return <ListLibroAuxiliarProveedores />
      default:
        return <div></div>
    }
  }
  return (
    <>
      <Layout>
        <div>
          <NavBar />
          {renderContent()}
        </div>
      </Layout>
    </>
  )
}

export default Informes
