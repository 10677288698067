import { useContentTypeReportes } from '../../../../../../pages/Reportes/useReportes.hook'
import FavoriteNavBar from './FavoriteNavBar'
import ListLibroDiario from '../../../../../../pages/Reportes/libroDiario/ListLibroDiario'
import ListLibroMayor from '../../../../../../pages/Reportes/libroMayor/ListLibroMayor'
import ListEstadoDeResultados from '../../../../../../pages/Reportes/estadoDeResultado/ListEstadoDeResultados'
import ListBalanceGeneral from '../../../../../../pages/Reportes/balanceGeneral/ListBalanceGeneral'
import ListLibroAuxiliarCliente from '../../../../../../pages/Reportes/libroAuxiliarCliente/ListLibroAuxiliarCliente'
import ListLibroAuxiliarProveedores from '../../../../../../pages/Reportes/libroAuxiliarProveedores/ListLibroAuxiliarProveedores'

const Index = () => {
  const favoriteContent = useContentTypeReportes(state => state.favoriteContent)
  const favoriteIcons = useContentTypeReportes(state => state.favoriteIcons)

  const renderFavoriteContent = () => {
    switch (favoriteContent) {
      case '1':
        return <div></div>
      case '2':
        return <ListEstadoDeResultados />
      case '3':
        return <div></div>
      case '4':
        return <div></div>
      case '5':
        return <ListBalanceGeneral />
      case '6':
        return <ListLibroDiario />
      case '7':
        return <ListLibroMayor />
      case '8':
        return <ListLibroAuxiliarCliente />
      case '9':
        return <ListLibroAuxiliarProveedores />
      default:
        return <div></div>
    }
  }

  return (
    <div>
      <FavoriteNavBar />
      {favoriteIcons.length > 0 && favoriteContent && renderFavoriteContent()}
    </div>
  )
}

export default Index
