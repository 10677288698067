import { useState, useEffect, useContext } from 'react'
import { HeadingTitle, Paper} from '../../../components/modules/ui/index'
import Select from '../../../components/modules/ui/Select'
import {
    currentMonth,
    currentYear,
    monthsOptions as months,
    yearsOptions
  } from '../../../components/utils/constant'
import CustomButton from '../../../components/modules/ui/CustomButton'
import Table from './Table'
import GlobalContext from '../../../context/global-context'
import { BusinessAPI } from '../../../api/business.api'

const ListEstadoDeResultados = () => {
  const [monthFilter, setMonthFilter] = useState(currentMonth)
  const [yearFilter, setYearFilter] = useState(currentYear)
  const { ui, currentBusiness } = useContext(GlobalContext)
  const businessAPI = new BusinessAPI()
  const [data, setData] = useState([])

  const monthsOptions = months.map(monthOption => ({
    ...monthOption,
    disabled: yearFilter == currentYear && monthOption.value > currentMonth
  }))

  const getData = async () => {
    const monthFormatter = monthFilter.toString().length === 1 ? `0${monthFilter}` : monthFilter

    if (currentBusiness.id) {
      ui.setLoader({ visible: true, text: 'Cargando los datos de la tabla...' })
      await businessAPI
        .getEstadoResultado({
          businessId: currentBusiness.id,
          month: monthFormatter,
          year: yearFilter
        })
        .then(({ data }) => {
          setData(data)
        })
        .catch(error => {
          setData([])
          console.error(error)
        })
        .finally(() => {
          ui.setLoader({ visible: false, text: '' })
        })
    } else {
      console.error('No dato')
    }
  }

  useEffect(() => {
    getData()
  }, [currentBusiness])
    

  return (
    <>
    <Paper>
        <HeadingTitle title='Estado de Resultados'/>
    <div>
      <div className='flex gap-2 items-end'>
        <Select
          options={monthsOptions}
          defaultValue={monthFilter}
          onChange={e => setMonthFilter(e.target.value)}
        />
        <Select
          options={yearsOptions.filter(year => year.value === 2024)}
          defaultValue={yearFilter}
          onChange={e => setYearFilter(e.target.value)}
        />
        
        <CustomButton
          className='rounded-full'
          color='primary'
          title='Buscar'
          onClick={getData}
        />
      </div>
    </div>
        <Table 
          rows={data}
          getData={getData}
        />
    </Paper>
    </>
  )
}

export default ListEstadoDeResultados
