import { useState } from 'react'
import { HeadingTitle, Paper, SearchInput } from '../../../../ui/index'
import TableComprobante from './TableComprobante'
import { MenuItem, Select } from '@mui/material'
import CustomButton from '../../../../ui/CustomButton'
import ModalNuevoMiContador from '../../../../modalDialogMiContador/ModalNuevoMiContador'

const List = () => {
    const [search, setSearch] = useState('')
    const [filters, setFilters] = useState({
        ingreso: true,
        egreso: true,
        traspaso: true,
        todos: true,
    })
    const [openNuevoComprobante, setOpenNuevoComprobante] = useState(false)

    const handleFilterChange = (event) => {
        setFilters({
            ...filters,
            [event.target.name]: event.target.checked,
        })
    }


    const handleOpenNuevoComprobante = () => {
        setOpenNuevoComprobante(true)
    }

    return (
        <>
        <div className='relative'>
            <div className='absolute top-[-20px] right-0 z-20 flex space-x-4'>
                <CustomButton className='rounded-full' color='accent2' title='AGREGAR NUEVO COMPROBANTE' onClick={handleOpenNuevoComprobante} />
                <CustomButton className='rounded-full' color='disabled' title='CARGAR' />
                <CustomButton title='EXPORTAR' color='primary' className='rounded-full' />
            </div>
        </div>

        <Paper >
            <HeadingTitle title='Comprobante'/>
    
                <div className='grid grid-cols-5 gap-4'>
                    <div className='col-span-2'>
                        <div className='flex items-center space-x-2'>
                            <span>Mostrar</span>
                            <Select defaultValue='5' className='mr-4 bg-white' variant='outlined' size='small'>
                                <MenuItem value='5'>5</MenuItem>
                                <MenuItem value='10'>10</MenuItem>
                                <MenuItem value='15'>15</MenuItem>
                            </Select>
                            <span>movimientos</span>
                        </div>
                    </div>
    
                    <div className='col-span-2 col-start-4 flex items-center justify-end'>
                        <SearchInput placeholder='Buscar reporte' classNameBorder='rounded-full'></SearchInput>
                    </div>

                    <div className='col-span-5 grid grid-cols-5 gap-4'>
                        <div className='col-span-2'>
                            <input
                                placeholder='Número de comprobante, documento'
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                size='small'
                                className='w-full'
                            />
                        </div>
    
                        <div className='col-span-1'>
                            <input
                                className='border border-gray-300 rounded-lg h-9 focus:outline-none focus:ring-0 hover:border-gray-400'
                                type='date'
                                name='desde'
                            />
                        </div>
    
                        <div className='col-span-1'>
                            <input
                                className='border border-gray-300 rounded-lg h-9 focus:outline-none focus:ring-0 hover:border-gray-400'
                                type='date'
                                name='hasta'
                            />
                        </div>
    
                        <div className='col-span-1'>
                            <div className='grid grid-cols-2 gap-2 text-xs'>
                                <div className='flex items-center space-x-2'>
                                    <input
                                        type='checkbox'
                                        name='ingreso'
                                        checked={filters.ingreso}
                                        onChange={handleFilterChange}
                                    />
                                    <label htmlFor='ingreso'>Ingreso</label>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <input
                                        type='checkbox'
                                        name='egreso'
                                        checked={filters.egreso}
                                        onChange={handleFilterChange}
                                    />
                                    <label htmlFor='egreso'>Egreso</label>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <input
                                        type='checkbox'
                                        name='traspaso'
                                        checked={filters.traspaso}
                                        onChange={handleFilterChange}
                                    />
                                    <label htmlFor='traspaso'>Traspaso</label>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <input
                                        type='checkbox'
                                        name='todos'
                                        checked={filters.todos}
                                        onChange={handleFilterChange}
                                    />
                                    <label htmlFor='todos'>Todos</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TableComprobante />
            </Paper>
            {openNuevoComprobante && (
                <ModalNuevoMiContador 
                    title='NuevoComprobante'
                    openNuevoComprobante={openNuevoComprobante}
                    setOpenNuevoComprobante={setOpenNuevoComprobante}
                />
            )}
        </>
    )
}

export default List
