import { Routes, Route } from 'react-router-dom'
import SignInForm from '../components/modules/authentication/SignInForm'
import { SignUpPage } from '../components/modules/authentication/SignUp/part1/SignUpPage'
import { CompleteSignUp } from '../components/modules/authentication/SignUp/part2/CompleteSignUp'
// material
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { esES } from '@mui/material/locale'
import { AuthContextProvider } from '../context/AuthContext'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import GlobalContextProvider from '../context/GlobalState'
import Loader from '../components/modules/Loader'
import { ToastContainer } from 'react-toastify'
import ModalDialog from '../components/modules/ModalDialog'
import AuthGuard from './AuthGuard'
import BusinessInsights from '../pages/BusinessInsights'
import Administracion from '../pages/Administracion'
import Reportes from '../pages/Reportes/Reportes'
import MiContador from '../pages/MiContador'
import { AuthLayout } from '../components/modules/authentication/components/SignUpLayout'
import { ResetPassword } from '../components/modules/authentication/resetPassword/part1/ResetPassword'
import { ActionAuth } from '../components/modules/authentication/ActionAuth'
import { CompleteResetPassword } from '../components/modules/authentication/resetPassword/part2/CompleteResetPassword'
import { FormCompleteInvitation } from '../components/modules/authentication/SignUp/completeInvitation/FormCompleteInvitation'

// styles
const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#00CCCC'
      },
      secondary: {
        main: '#FF3A0B'
      }
    }
  },
  esES
)

const AppRoutes = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalContextProvider>
        <AuthContextProvider>
          <Loader />
          <ModalDialog />
          <ToastContainer
            position='bottom-right'
            style={{ position: 'fixed', bottom: 40, right: 70 }}
          />
          <QueryClientProvider client={new QueryClient()}>
            <Routes>
              <Route path='/auth' element={<AuthLayout />}>
                <Route path='signin' element={<SignInForm />} />
                <Route path='signup' element={<SignUpPage />} />
                <Route path='reset-password' element={<ResetPassword />} />

                <Route path='action' element={<ActionAuth />} />
                <Route path='complete-signup' element={<CompleteSignUp />} />
                <Route path='complete-reset-password' element={<CompleteResetPassword />} />
                <Route path='complete-invitation' element={<FormCompleteInvitation />} />
              </Route>

              <Route path='/' element={<AuthGuard />}>
                <Route path='' element={<BusinessInsights />} />
                <Route path='BusinessInsights' element={<BusinessInsights />} />
                <Route path='administracion' element={<Administracion />} />
                <Route path='reportes' element={<Reportes />} />
                <Route path='micontador' element={<MiContador />} />
              </Route>
            </Routes>
          </QueryClientProvider>
        </AuthContextProvider>
      </GlobalContextProvider>
    </ThemeProvider>
  )
}

export default AppRoutes
