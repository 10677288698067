import { Dialog, DialogContent } from '@mui/material'
import { Fragment } from 'react'
import CloseIcon from '@mui/icons-material/CloseOutlined'
import CustomButton from '../ui/CustomButton'
import TablaNuevoComprobante from '../views/dashboard/MiContador/Comprobante/TablaNuevoComprobante'

const ModalNuevoMiContador = ({
    openNuevoComprobante,
     setOpenNuevoComprobante
    }) => {

    const handleClose = () => {
        setOpenNuevoComprobante(false)
    }

    return (
        <div>
          <Fragment>
            <Dialog open={openNuevoComprobante} maxWidth='lg' onClose={handleClose}>
              <DialogContent className='bg-gray-100'>
                <div className='flex justify-between items-center p-4 border-b'>
                  <h2 className='text-lg font-semibold'>Nuevo Comprobante</h2>
                  <button onClick={handleClose} className='text-gray-400 hover:text-gray-600'>
                    <CloseIcon />
                  </button>
                </div>
      
                <div className='bg-gray-100 rounded-lg shadow-lg p-6 mt-6'>
                    <div className='grid grid-cols-4 gap-4'>
                      <div>
                        <label className='block text-sm font-medium text-gray-700'>Número de Comprobante</label>
                        <input
                          type='text'
                          className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                          value=''
                        />
                      </div>
                      <div>
                        <label className='block text-sm font-medium text-gray-700'>Fecha</label>
                        <input
                          type='date'
                          className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                          value=''
                        />
                      </div>
                      <div>
                        <label className='block text-sm font-medium text-gray-700'>Tipo</label>
                        <select className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'>
                          <option>Egreso</option>
                        </select>
                      </div>
                      <div>
                        <label className='block text-sm font-medium text-gray-700'>Tipo de Documento</label>
                        <select className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'>
                          <option>Validado</option>
                        </select>
                      </div>
                    </div>
                    <div className='mt-4'>
                      <label className='block text-sm font-medium text-gray-700'>Glosa</label>
                      <textarea className='mt-1 w-full border-gray-300 rounded-md shadow-sm' rows='3'></textarea>
                    </div>
      
                    {/* Tabla */}
                    <TablaNuevoComprobante />
      
                    {/* Botones */}
                    <div className='flex justify-between items-center mt-6 space-x-2 pb-6'>
                        <CustomButton className='rounded-full' color='accent2' title='AGREGAR' />
                        <CustomButton className='rounded-full' color='disabled' title='GUARDAR BORRADOR' />
                        <CustomButton className='rounded-full' color='primary' title='CERRAR' onClick={handleClose}/>
                    </div>
                </div>
              </DialogContent>
            </Dialog>
          </Fragment>
        </div>
      )
}

export default ModalNuevoMiContador
