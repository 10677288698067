import { useState } from 'react'

const TablaNuevoComprobante = () => {
    const [rows, setRows] = useState([
        { cuenta: '', glosa: '', centroCostos: '', sucursal: '', debe: '', haber: '' },
      ]);
    
      // Función para agregar una nueva fila
      const handleAddRow = () => {
        setRows([
          ...rows,
          { cuenta: '', glosa: '', centroCostos: '', sucursal: '', debe: '', haber: '' },
        ]);
      };
    
      // Función para manejar cambios en los inputs
      const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedRows = rows.map((row, i) => (
          i === index ? { ...row, [name]: value } : row
        ));
        setRows(updatedRows);
      };
    
      return (
        <div className='border rounded-lg p-4 mt-4 bg-white'>
          <table className='min-w-full table-fixed border-collapse text-sm text-left'>
            <thead>
              <tr className='p-2'>
                <th>Cuenta</th>
                <th>Glosa</th>
                <th>Centro de Costos</th>
                <th>Sucursal</th>
                <th>Debe</th>
                <th>Haber</th>
                <th></th> {/* Columna para el botón "+" */}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr className='border-b p-2' key={index}>
                  <td>
                    <input
                      type='text'
                      name='cuenta'
                      value={row.cuenta}
                      onChange={(e) => handleInputChange(index, e)}
                      className='border rounded p-1 w-full'
                    />
                  </td>
                  <td>
                    <input
                      type='text'
                      name='glosa'
                      value={row.glosa}
                      onChange={(e) => handleInputChange(index, e)}
                      className='border rounded p-1 w-full'
                    />
                  </td>
                  <td>
                    <input
                      type='text'
                      name='centroCostos'
                      value={row.centroCostos}
                      onChange={(e) => handleInputChange(index, e)}
                      className='border rounded p-1 w-full'
                    />
                  </td>
                  <td>
                    <input
                      type='text'
                      name='sucursal'
                      value={row.sucursal}
                      onChange={(e) => handleInputChange(index, e)}
                      className='border rounded p-1 w-full'
                    />
                  </td>
                  <td>
                    <input
                      type='number'
                      name='debe'
                      value={row.debe}
                      onChange={(e) => handleInputChange(index, e)}
                      className='border rounded p-1 w-full'
                    />
                  </td>
                  <td>
                    <input
                      type='number'
                      name='haber'
                      value={row.haber}
                      onChange={(e) => handleInputChange(index, e)}
                      className='border rounded p-1 w-full'
                    />
                  </td>
                  <td>
                    {/* Botón "+" para agregar una nueva fila */}
                    {index === rows.length - 1 && (
                      <button
                        onClick={handleAddRow}
                        className='bg-blue-500 text-white px-2 py-1 rounded'
                      >
                        +
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
    )
}
export default TablaNuevoComprobante
