import React, { useState } from 'react'
import { InputCheckBox, Text } from '../../../../ui'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ModalEditMiContador from '../../../../modalDialogMiContador/ModalEditMiContador'
import UpIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import DownIcon from '@mui/icons-material/KeyboardArrowDownOutlined'

const TableComprobante = () => {
    const [order, setOrder] = useState({ field: null, direction: 'asc' })
    const [openModal, setOpenModal] = useState(false)
    const [expandedRows, setExpandedRows] = useState([])

    // Columnas estáticas
    const cols = [
        { name: '', field: 'checkear'},
        { name: 'Fecha', field: 'fecha' },
        { name: 'Origen', field: 'origen' },
        { name: 'Tipo', field: 'tipo' },
        { name: 'Número', field: 'numero' },
        { name: 'Glosa', field: 'glosa' },
        { name: 'Total', field: 'total' },
        { name: '', field: 'editar'}
    ]

    // Datos estáticos
    const rows = [
        { fecha: '22-02-2023', origen: 'Banco Bice', tipo: 'Lipsum', numero: '001', glosa: 'Comentarios aquí', total: 2000000 },
        { fecha: '20-02-2023', origen: 'Banco Santander', tipo: 'Lipsum', numero: '002', glosa: 'Comentarios aquí', total: 1000000 },
        { fecha: '16-02-2023', origen: 'Facturas por pagar', tipo: 'Lipsum', numero: '003', glosa: 'Comentarios aquí', total: 800000 },
        { fecha: '15-02-2023', origen: 'Caja Principal', tipo: 'Lipsum', numero: '004', glosa: 'Comentarios aquí', total: 1200000 }
    ]

    const handleSort = (field) => {
        const isAsc = order.field === field && order.direction === 'asc'
        setOrder({ field, direction: isAsc ? 'desc' : 'asc' })
    }

    const sortedRows = [...rows].sort((a, b) => {
        if (!order.field) return 0
        if (order.direction === 'asc') {
            return a[order.field] > b[order.field] ? 1 : -1
        }
        return a[order.field] < b[order.field] ? 1 : -1
    })

    const handleClickOpen = () => {
        setOpenModal(true)
    }

    const toggleRow = (index) => {
        if (expandedRows.includes(index)) {
            setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index)) // Cerrar la fila
        } else {
            setExpandedRows([...expandedRows, index]) // Expandir la fila
        }
    }

    const styles = {
        table: 'w-full text-[#000231] text-xs border-collapse leading-6 overflow-x-auto table-fixed',
        tr: 'border-b border-gray-200 hover:bg-gray-100/50',
        td: 'text-left align-top p-4 whitespace-nowrap max-w-52 overflow-x-auto no-scrollbar-space-y-1',
        th: 'border-2 border-transparent border-b-gray-200 text-left p-4 whitespace-nowrap',
        boldText: 'font-bold',
        text: 'text-xs text-gray-500'
    }

    return (
        <div className=''>
            <div className='overflow-x-auto'>
                <table className={[styles.table]}>
                    <thead>
                        <tr>
                            {cols.map((col) => (
                                <th
                                    key={col.field}
                                    className={styles.th}
                                    onClick={() => handleSort(col.field)}
                                >
                                    {col.name}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedRows.map((row, index) => (
                            <React.Fragment key={index}>
                                <tr className='border-b px-4 py-2' >
                                    <td>
                                        <InputCheckBox />
                                    </td>
                                    <td>
                                        <Text className='font-bold'>{row.fecha}</Text>
                                    </td>
                                    <td>
                                        <div className='flex items-center'>
                                            <Text className='font-bold'>{row.origen}</Text>
                                            <button onClick={() => toggleRow(index)} className='mr-2'>
                                                {expandedRows.includes(index) ? (
                                                    <UpIcon className='h-5 w-5 text-gray-500' />
                                                ) : (
                                                    <DownIcon className='h-5 w-5 text-gray-500' />
                                                )}
                                            </button>
                                        </div>
                                    </td>
                                    <td>
                                        <Text className='font-bold'>{row.tipo}</Text>
                                    </td>
                                    <td>
                                        <Text className='font-bold'>{row.numero}</Text>
                                    </td>
                                    <td>
                                        <Text className='font-bold'>{row.glosa}</Text>
                                    </td>
                                    <td>
                                        <Text className='font-bold'>${row.total.toLocaleString()}</Text>
                                    </td>
                                    <td>
                                        <div className='flex flex-col text-xs text-gray-500 items-center justify-center hover:cursor-pointer'>
                                            <div 
                                                className='bg-white flex text-slate-400 shadow-full-xs rounded-full items-center justify-center w-9 h-9 hover:bg-slate-100/50 transition-colors duration-150'
                                                onClick={handleClickOpen}
                                            >
                                                <EditOutlinedIcon />
                                            </div>
                                            <Text className='mt-2'>Editar</Text>
                                        </div>
                                    </td>
                                </tr>
                                {expandedRows.includes(index) && (
                                    <tr className='bg-gray-50'>
                                        <td colSpan={8} className='p-4'>
                                            <div className='overflow-x-auto'>
                                                <table className='table-auto w-full'>
                                                    <thead>
                                                        <tr className='px-4 py-2'>
                                                            <th>Razón Social</th>
                                                            <th>Tipo Documento</th>
                                                            <th># Folio</th>
                                                            <th>Fecha Vencimiento</th>
                                                            <th>Debe</th>
                                                            <th>Haber</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className='text-center px-4 py-2'>
                                                            <td>Santa Trinidad</td>
                                                            <td>Factura</td>
                                                            <td>98722</td>
                                                            <td>31/02/2025</td>
                                                            <td></td>
                                                            <td>$800.000</td>
                                                        </tr>
                                                        <tr className='text-center px-4 py-2'>
                                                            <td>Santa Trinidad</td>
                                                            <td>Factura</td>
                                                            <td>98722</td>
                                                            <td>31/02/2025</td>
                                                            <td></td>
                                                            <td>$800.000</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
            {openModal && (
                <ModalEditMiContador
                    title='EditarComprobante'
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                />
            )}
        </div>
    )
}

export default TableComprobante
