import { useState, useEffect } from 'react'

const useBlockedMonths = () => {
  const [blockedMonths, setBlockedMonths] = useState(() => {
    const storedMonths = localStorage.getItem('blockedMonths')
    return storedMonths ? JSON.parse(storedMonths) : Array(12).fill(false)
  })

  const [allMonthsBlocked, setAllMonthsBlocked] = useState(false)

  useEffect(() => {
    const allBlocked = blockedMonths.every((month) => month)
    setAllMonthsBlocked(allBlocked)
    localStorage.setItem('blockedMonths', JSON.stringify(blockedMonths)) 
  }, [blockedMonths])

  return {
    blockedMonths,
    setBlockedMonths,
    allMonthsBlocked
  }
}

export default useBlockedMonths
