import React, { useState } from 'react'
import clsx from 'clsx'
//import { colsCliente as cols } from '../../../components/modules/data/headersTables'
import CustomPagination from '../../../components/modules/table/CustomPagination'
import { Text } from '../../../components/modules/ui'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { formatCurrency, formatDate } from '../../../components/utils/formatter'
import { orderableCols, styles } from './dataProveedor'

const Table = ({ rows, rowsPerPage, setRowsPerPage }) => {
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState({ col: 'Folio', field: 'numeroFolio', direction: 'desc' })

  const handleSort = ({ col, field }) => {
    const isAsc = order.col === col && order.direction === 'asc'
    setOrder({ col, field, direction: isAsc ? 'desc' : 'asc' })
  }

  const groupRows = () => {
    const grouped = rows.reduce((acc, row) => {
      const key = row.razonSocial
      // Solo procesar filas que tengan valores relevantes en DEBE o HABER y que sean objetos principales
      if ((row.debe !== 0 || row.haber !== 0) && row.comprobante !== undefined) {
        if (!acc[key]) {
          acc[key] = { razonSocial: key, debe: 0, haber: 0, saldo: 0, entries: [] }
        }
        acc[key].debe += row.debe || 0
        acc[key].haber += row.haber || 0
        acc[key].entries.push(row) 
      }
      return acc
    }, {})

    return Object.values(grouped).filter(group => group.debe !== 0 || group.haber !== 0)
  }

  const groupedRows = groupRows()
    .sort((a, b) => {
      const comparison = (a.razonSocial || '').localeCompare(b.razonSocial || '')
      return order.direction === 'asc' ? comparison : -comparison
    })

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - groupedRows.length) : 0

  const handleChangePage = newPage => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = value => {
    setRowsPerPage(value)
    setPage(0)
  }

  if (!groupedRows?.[0]) return <Text className='grid place-items-center py-12'>No hay datos</Text>

  return (
    <div>
      <div className='overflow-x-auto'>
        <table className={styles.table}>
          <thead>
            <tr>
              {orderableCols.map((col, i) => (
                <th
                  className={clsx(styles.th, { 'cursor-pointer': true })}
                  onClick={() => handleSort(col)}
                  key={i}
                >
                  {col.col}
                  <KeyboardArrowDownIcon
                    className={clsx('transition-all ml-2 cursor-pointer', {
                      'opacity-40': order.col !== col.field,
                      'rotate-180': order.col === col.field && order.direction === 'desc'
                    })}
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {groupedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((group, i) => {
              let cumulativeSaldo = 0 
              let totalDebe = 0 
              let totalHaber = 0

              return (
                <React.Fragment key={i}>
                  {group.entries.map((row) => {
                    cumulativeSaldo += (row.haber || 0) - (row.debe || 0)
                    totalDebe += row.debe || 0
                    totalHaber += row.haber || 0

                    return (
                      <tr className={styles.tr} key={i}>
                        {/* Rut */}
                        <td className={styles.td}>
                          <Text className={styles.boldText}>{row.rut}</Text>
                        </td>
                        {/* Cliente */}
                        <td className={clsx(styles.td)}>
                          <Text className={styles.boldText}>{row.razonSocial}</Text>
                        </td>
                        {/* fecha */}
                        <td className={styles.td}>
                          <Text className={styles.boldText}>{row.fecha && formatDate(row.fecha)}</Text>
                        </td>
                        {/* vencimiento */}
                        <td className={styles.td}>
                          <Text className={styles.boldText}>{row.fechaVencimiento && formatDate(row.fechaVencimiento)}</Text>
                        </td>
                        {/*Comprobante*/}
                        <td className={styles.td}>
                          <Text className={styles.boldText}>{row.comprobante}</Text>
                        </td>
                        {/*TipoComprobante*/}
                        <td className={styles.td}>
                          <Text className={styles.boldText}>{row.tipoComprobante}</Text>
                        </td>
                        {/*
                        <td className={styles.td}>
                          <Text className={styles.boldText}>{'N/A CENTRO COSTO'}</Text>
                        </td>
                        <td className={styles.td}>
                          <Text className={styles.boldText}>{'N/A SUCURSAL'}</Text>
                        </td>/*}
                        {/* Documento */}
                        <td className={styles.td}>
                          <Text className={clsx(styles.boldText)}>{row.folio}</Text>
                        </td>
                        {/*Tipo Documento*/}
                        <td className={styles.td}>
                          <Text className={clsx(styles.boldText)}>{row.nombreFolio}</Text>
                        </td>
                        {/* Debe*/}
                        <td className={styles.td}>
                          <Text className={clsx(styles.text, 'text-right')}>
                            {formatCurrency(row.debe)}
                          </Text>
                        </td>
                        {/* Haber */}
                        <td className={styles.td}>
                          <Text className={clsx(styles.text, 'text-right')}>
                            {formatCurrency(row.haber)}
                          </Text>
                        </td>
                        {/* Saldo*/}
                        <td className={styles.td}>
                          <Text className={clsx(styles.text, 'text-right')}>
                            {formatCurrency(row.saldo)}
                          </Text>
                        </td>
                      </tr>
                    )
                  })}
                  <tr className={styles.tr}>
                    <td className={styles.td} colSpan={7}>
                    </td>
                    <td className={styles.td}>
                      <Text className={styles.boldText}>TOTAL</Text>
                    </td>
                    <td className={styles.td}>
                      <Text className={clsx(styles.boldText, 'text-right font-bold')}>
                        {formatCurrency(totalDebe)}
                      </Text>
                    </td>
                    <td className={styles.td}>
                      <Text className={clsx(styles.boldText, 'text-right font-bold')}>
                        {formatCurrency(totalHaber)}
                      </Text>
                    </td>
                    <td className={styles.td}>
                      <Text className={clsx(styles.boldText, 'text-right font-bold')}>
                        {formatCurrency(cumulativeSaldo)}
                      </Text>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={orderableCols.length} className='border-t-2'></td>
                  </tr>
                </React.Fragment>
              )
            })}
            {emptyRows > 0 && (
              <tr className={styles.tr} style={{ height: 89 * emptyRows }}>
                <td className={styles.td} colSpan={orderableCols.length} aria-hidden />
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <CustomPagination
        count={groupedRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  )
}

export default Table