import CustomButton from '../../../ui/CustomButton'
import { HeadingTitle, Paper } from '../../../ui/index'
import LockIcon from '@mui/icons-material/Lock'
import useBlockedMonths from './useBlockedMonths.hook'

const months = [
  { name: 'ENERO', year: '2023', number: '01' },
  { name: 'FEBRERO', year: '2023', number: '02' },
  { name: 'MARZO', year: '2023', number: '03' },
  { name: 'ABRIL', year: '2023', number: '04' },
  { name: 'MAYO', year: '2023', number: '05' },
  { name: 'JUNIO', year: '2023', number: '06' },
  { name: 'JULIO', year: '2023', number: '07' },
  { name: 'AGOSTO', year: '2023', number: '08' },
  { name: 'SEPTIEMBRE', year: '2023', number: '09' },
  { name: 'OCTUBRE', year: '2023', number: '10' },
  { name: 'NOVIEMBRE', year: '2023', number: '11' },
  { name: 'DICIEMBRE', year: '2023', number: '12' }
]

const BloqueDePeriodos = () => {
  const { blockedMonths, setBlockedMonths } = useBlockedMonths()

  const toggleBlock = (index) => {
    setBlockedMonths((prevState) =>
      prevState.map((blocked, i) => (i === index ? !blocked : blocked))
    )
  }

  const blockAll = () => {
    setBlockedMonths(Array(12).fill(true))
  }

  return (
    <Paper>
      <div className='flex items-center justify-between mb-4'>
        <HeadingTitle title='Bloqueo de Períodos' />
        <div className='flex justify-end'>
          <CustomButton className='rounded-full text-xs' color='primary' title='BLOQUEAR TODO' onClick={blockAll} />
        </div>
      </div>
      <div className='grid grid-cols-6 gap-4'>
        {months.map((month, index) => (
          <div
            key={index}
            onClick={() => toggleBlock(index)}
            className={`border rounded-md flex flex-col items-center justify-center p-4 relative h-48 cursor-pointer 
              ${blockedMonths[index] ? 'bg-gray-500 text-white' : 'bg-white text-black'}`}
          >
            <div className={`absolute text-7xl font-bold top-0 right-2 
              ${blockedMonths[index] ? 'text-gray-300' : 'text-gray-200'}`}>
              {month.number}
            </div>
            <div className='text-center z-10'>
              <h3 className='font-bold text-lg'>{month.name}</h3>
              <p className='text-sm'>{month.year}</p>
            </div>
            <LockIcon className={`mt-2 ${blockedMonths[index] ? 'text-white' : 'text-gray-500'}`} />
          </div>
        ))}
      </div>
    </Paper>
  )
}

export default BloqueDePeriodos
