import { Dialog, DialogContent } from '@mui/material'
import { Fragment } from 'react'
import { InputCheckBox } from '../ui'
import CloseIcon from '@mui/icons-material/CloseOutlined'
import CustomButton from '../ui/CustomButton'
import CancelIcon from '@mui/icons-material/HighlightOffOutlined'

const ModalEditMiContador = ({openModal, setOpenModal}) => {

    const handleClose = () => {
        setOpenModal(false)
    }

    return (
        <div>
          <Fragment>
            <Dialog open={openModal} maxWidth='lg' onClose={handleClose}>
              <DialogContent className='bg-gray-100'>
                <div className='flex justify-between items-center p-4 border-b'>
                  <h2 className='text-lg font-semibold'>Editar Comprobante</h2>
                  <button onClick={handleClose} className='text-gray-400 hover:text-gray-600'>
                    <CloseIcon />
                  </button>
                </div>
      
                <div className='bg-gray-100 rounded-lg shadow-lg p-6 mt-6'>
                    <div className='grid grid-cols-4 gap-4'>
                      <div>
                        <label className='block text-sm font-medium text-gray-700'>Número de Comprobante</label>
                        <input
                          type='text'
                          className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                          value=''
                        />
                      </div>
                      <div>
                        <label className='block text-sm font-medium text-gray-700'>Fecha</label>
                        <input
                          type='date'
                          className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                          value=''
                        />
                      </div>
                      <div>
                        <label className='block text-sm font-medium text-gray-700'>Tipo</label>
                        <select className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'>
                          <option>Egreso</option>
                        </select>
                      </div>
                      <div>
                        <label className='block text-sm font-medium text-gray-700'>Tipo de Documento</label>
                        <select className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'>
                          <option>Validado</option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <label className='block text-sm font-medium text-gray-700'>Glosa</label>
                      <textarea className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'></textarea>
                    </div>
      
                    {/* Tabla */}
                    <div className='border rounded-lg p-4 mt-4 bg-white'>
                      <table className='min-w-full table-fixed border-collapse text-sm text-left text-gray-500'>
                        <thead className=''>
                          <tr className='p-2'>
                            <th></th>
                            <th>Cuenta</th>
                            <th>Glosa</th>
                            <th>Centro de Costos</th>
                            <th>Sucursal</th>
                            <th>Debe</th>
                            <th>Haber</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className='border-b p-2'>
                            <td><InputCheckBox /></td>
                            <td>Banco Bice</td>
                            <td>Lipsum</td>
                            <td></td>
                            <td>Lipsum</td>
                            <td>$501.000</td>
                            <td>$0</td>
                            <td className='p-2 text-right'>
                              <CancelIcon />
                            </td>
                          </tr>
                          {/* Agrega más filas según tus datos */}
                        </tbody>
                        <tfoot>
                          <tr className='border-t'>
                            <td colSpan='4' className='p-2 font-semibold text-right'>Totales:</td>
                            <td className='p-2'>$501.000</td>
                            <td className='p-2'>$501.000</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
      
                    {/* Botones */}
                    <div className='flex justify-between items-center mt-6 space-x-2 pb-6'>
                        <CustomButton className='rounded-full' color='accent2' title='MODIFICAR COMPROBANTE' />
                        <CustomButton className='rounded-full' color='disabled' title='GUARDAR BORRADOR' />
                        <CustomButton className='rounded-full' color='primary' title='OMITIR POR AHORA' />
                        <CustomButton className='rounded-full' color='primary' title='CERRAR' onClick={handleClose}/>
                    </div>
                </div>
              </DialogContent>
            </Dialog>
          </Fragment>
        </div>
      )
}

export default ModalEditMiContador
